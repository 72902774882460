import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import config from "config";
import axios from "axios";

const DocumentCreateModal = (props) => {
  const { project, onChange, document, onHide } = props;
  const authenticationContext = useContext(AuthenticationContext);
  const [errors, setErrors] = useState({ title: null, contents: null });
  const [values, setValues] = useState(
    document || { title: "", contents: "", project: project?.id }
  );
  const [totalWords, setTotalWords] = useState(0);

  const fetchDocument = async () => {
    const url = `api/v1.0/document/${document.id}/`;
    const method = "GET";
    const result = await sendRequest({
      url,
      method,
      token: authenticationContext.token || "",
    });
    setValues(result);
  };
  const onTextChange = (text) => {
    setValues({ ...values, contents: text });
    const wordList = text.match(/[^\s–—·]+/g) || [];
    const wordCount = wordList ? wordList.length : 0;
    setTotalWords(wordCount);
  };

  useEffect(() => {
    if (document) {
      fetchDocument();
    }
  }, []);

  const resultContainerRef = useRef(null);
  const uploadButtonRef = useRef(null);

  const onSuccess = () => {
    onHide();
    onChange();
    toast.success("Document created successfully.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    setValues({ title: "", contents: "", project: project?.id });
    setErrors({ title: null, contents: null });
  };

  const onFail = (error) => {
    toast.error(
      "Something failed submitting your document. Check the fields for errors.",
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
    const errs = JSON.parse(error.message);
    setErrors(errs);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const url = "api/v1.0/document/" + (document ? `${document.id}/` : "");
      const method = document ? "PUT" : "POST";
      await sendRequest({
        url,
        method,
        token: authenticationContext.token || "",
        body: JSON.stringify({ ...values, word_count: totalWords }),
      });
      onSuccess();
    } catch (error) {
      onFail(onFail);
    }
  };

  const extractBlobs = (txt) => {
    const url = document.location.host;
    const regex = new RegExp("blob:http(s?)://" + url + "/[-a-zA-Z0-9-]*");
    return txt.match(regex);
  };

  const blobToBase64 = async (url) => {
    let blob = await fetch(url).then((r) => r.blob());
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const onUpload = async (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        `${config.BASE_API_URL}file-upload-api/`,
        formData,
        {
          headers: {
            Authorization: "Token " + authenticationContext.token || "",
          },
        }
      );
      setValues({ ...values, contents: response.data });
    }
  };

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {document ? "Edit " : "Create New "} Document{" "}
          {project?.title && "in " + project.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <InputGroup hasValidation className="mb-3" controlId="document.title">
            <Form.Control
              placeholder="Title"
              type="text"
              required
              isInvalid={errors.title}
              autoFocus
              value={values.title}
              onChange={(e) => setValues({ ...values, title: e.target.value })}
            />
            <Form.Control.Feedback type={errors.title ? "invalid" : "valid"}>
              Please enter a title.
            </Form.Control.Feedback>
          </InputGroup>
          <Form.Group className="mb-3 no-margin" controlId="document.textarea">
            <ReactQuill
              placeholder="Type the content, paste it (recommended), or import with the button below."
              theme="snow"
              value={values.contents}
              onChange={onTextChange}
              className={errors.contents ? "error" : ""}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  [
                    "link",
                    "image",
                    { align: ["", "center", "right", "justify"] },
                  ],
                  ["clean"],
                ],
              }}
            />
          </Form.Group>
          <InputGroup
            hasValidation
            className="mb-3"
            controlId="document.contents"
          >
            <Form.Control
              placeholder="Contents"
              type="text"
              required
              isInvalid={errors.contents}
              autoFocus
              value={values.contents}
              style={{ display: "None" }}
            />
            <Form.Control.Feedback type={errors.contents ? "invalid" : "valid"}>
              Please enter a content for the document.
            </Form.Control.Feedback>
          </InputGroup>
          {errors.non_field_errors && (
            <Row style={{ padding: "0 1rem", color: "red" }}>
              {errors.non_field_errors}
            </Row>
          )}
        </Form>
        <div
          id="totalWordsContainer"
          class="sm-text pull-right"
          style={{ marginTop: "-10px" }}
        >
          Total words: {totalWords}
        </div>
      </Modal.Body>
      <Row style={{ padding: "0 1rem" }}>
        <Col sm={9}>
          <Button
            variant="primary"
            type="submit"
            className="purple"
            onClick={onSubmit}
          >
            Save
          </Button>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
        </Col>
        <Col sm={3}>
          <Button
            className="green"
            onClick={() => uploadButtonRef.current.click()}
          >
            Upload
          </Button>
          <input
            id="file"
            type="file"
            onChange={onUpload}
            ref={uploadButtonRef}
            style={{ display: "None" }}
          />
          <p className="upload-text">Upload your .docx and .rtf files</p>
          <div style={{ display: "None" }} ref={resultContainerRef} />
        </Col>
      </Row>
    </Modal>
  );
};

export default DocumentCreateModal;
