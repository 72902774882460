import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import Config from "../../config";
import './WhatKindsOfGroupsAreHostedOnInkedVoices.scss';

const BASE_API_URL = Config.BASE_API_URL;


const WhatKindsOfGroupsAreHostedOnInkedVoices = () => {
    return (
        <Container className="container about-view ink-what-kinds-of-groups-are-hosted-on-inked-voices">
            <Row>
                <Col md={{span: 9, offset: 1}}>
                    <Row>
                        <h1>What Kinds of Groups are Hosted on Inked Voices?</h1>

                        <h2>Critique Groups</h2>
                        <p>
                            Workshop your writing with your peers, using our user-friendly critiquing platform, which allows you 
                            to make both global and in-line comments as well as view the critiques of others. Our member-led critique 
                            groups encompass a wide variety of genres, for readers of every age. Some are fast-paced, with submissions 
                            and critiques due weekly. Others are relaxed, with members posting at will. Some are quite large, others 
                            intimate. Members are welcome to request an invite to more than one critique group, and to form their own. 
                            Admission to any given critique group is at the discretion of the admin.
                        </p>

                        <h2>Peer Pop-up Groups</h2>
                        <p>
                            Pop-up critique groups are short term. Members exchange just one round of submissions and critiques. 
                            Pop-ups are great for meeting writing partners without having to commit to an ongoing group and for getting 
                            fresh eyes on your writing. We host pop-ups in both adult and kid lit, in a wide range of genres. Contact us 
                            if you have an idea for a pop-up!
                        </p>

                        <h2>One-on-One Groups</h2>
                        <p>
                            Some writers prefer to work with just one other person. Members are free to use the site’s search function to 
                            browse through individual profiles (if the member has made their profile searchable). We recommend a test exchange 
                            of one piece of writing before making a long-term commitment. Finding the right match can be a process of trial 
                            and error, but we are there to help if you need us.
                        </p>

                        <h2>Accountability Groups</h2>
                        <p>
                            Our <a href={BASE_API_URL + "group/results/?q=&type_of_group=3&model=group"} target="_blank" rel="noopener noreferrer">member-led accountability groups </a> 
                            are for writers looking for community as well as some accountability in staying on task with their writing. Each group 
                            functions differently, but members typically post once a week to report on the previous week’s progress, to vent about 
                            writing set-backs, and to share their goals for the coming week. Members give a boost to one another by listening and 
                            posting supportive replies. Contact us for help in finding an accountability group.
                        </p>
                        <p>
                            We work with writers individually to help them find partners and groups. 
                            <Link to={`/team/`}> Contact the community lead in your category to ask for guidance</Link>.
                        </p>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default WhatKindsOfGroupsAreHostedOnInkedVoices;
