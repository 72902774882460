import config from "config";
import { useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const backend_url = config.BASE_API_URL?.slice(0, -1);

const DocumentDownloadCritiques = (props: any) => {
  const ref = useRef<null | HTMLFormElement>(null);
  const { show, onHide, submission } = props;

  const [selected, setSelected] = useState(
    submission?.finished_critiques?.map((critique: any) => critique.author_id)
  );

  const handleConfirm = (ref: any) => {
    const editorsStr = selected.join();
    const url = `${backend_url}/critique/print-preview/${submission.id}/${editorsStr}`;
    window.open(url, "_blank");
  };
  const handleClick = (evt: any, author_id: any) => {
    if (evt.currentTarget.checked) {
      setSelected([...selected, author_id]);
    } else {
      setSelected(selected.filter((e: any) => e !== author_id));
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form ref={ref}>
        <Modal.Header>
          <Modal.Title>Select Editors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="no-editors">
            <tr>
              <td>
                {submission?.finished_critiques.length === 0 &&
                  "There are no editors."}
                {submission?.finished_critiques?.map((critique: any) => (
                  <Form.Check
                    label={critique.author_name}
                    id={critique.author_id}
                    defaultChecked={true}
                    onClick={(evt: any) => handleClick(evt, critique.author_id)}
                  />
                ))}
              </td>
            </tr>
          </table>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            className="primary purple"
            onClick={() => {
              handleConfirm(ref);
            }}
          >
            Download PDF
          </Button>
          <Button
            variant="secondary"
            className="secondary grey"
            onClick={onHide}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DocumentDownloadCritiques;
